import { DateTime } from 'luxon';
import { PATIENT_API_URL, httpRequestHeaders } from 'utils/helpers/patientApi';
import { Address, ScheduledPickupAvailability } from './types';

export interface IResponse<T> {
  body?: T;
  status: 'success' | 'fail' | 'error';
}

export const getPatientAddress = async (activationCode: string): Promise<IResponse<Address>> => {
  const headers = httpRequestHeaders({});
  return new Promise((resolve) => {
    fetch(`${PATIENT_API_URL}/patients/${activationCode}`, {
      method: 'GET',
      headers,
    })
      .then(async (response: Response) => {
        if (response.status === 200) {
          const responseBody = await response.json();
          const address: Address = {
            address1: responseBody.address1,
            address2: responseBody.address2,
            city: responseBody.city,
            district1: responseBody.district1,
            postalCode: responseBody.postalCode,
            country: responseBody.country,
          };
          resolve({ body: address, status: 'success' });
        } else if (response.status === 404) {
          resolve({ status: 'fail' });
        } else {
          resolve({ status: 'error' });
        }
      })
      .catch((ex) => {
        console.error(ex);
        resolve({ status: 'error' });
      });
  });
};

export const getPickupAvailability = async (
  activationCode: string,
  address: Address
): Promise<IResponse<ScheduledPickupAvailability>> => {
  const headers = httpRequestHeaders({});
  return new Promise((resolve) => {
    fetch(`${PATIENT_API_URL}/scheduledPickupAvailability`, {
      method: 'POST',
      body: JSON.stringify({
        address,
        pickupDay: DateTime.now().toISODate(),
        activationCode,
      }),
      headers,
    })
      .then(async (response: Response) => {
        if (response.status === 200) {
          const responseBody = await response.json();
          resolve({ body: responseBody, status: 'success' });
        } else if (response.status === 400) {
          resolve({ status: 'fail' });
        } else {
          resolve({ status: 'error' });
        }
      })
      .catch((ex) => {
        console.error(ex);
        resolve({ status: 'error' });
      });
  });
};
