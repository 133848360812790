import styled from 'styled-components';
import { ThemeColors } from 'styles/constants';

export const AddressFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${ThemeColors.TassoHeritageRed};
  gap: 8px;
`;

export const AddressFormRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ScrollSpacer = styled.div`
  padding-top: 124px;
  background-color: ${ThemeColors.TassoWhite};
`;
