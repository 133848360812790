import React from 'react';

import { ListDivider, ListGradient } from 'components/GenericStyles/generic.styles';
import { useGlobalStore } from 'contexts/mainGlobalContext';
import { useMediaQueries } from 'utils/helpers/mediaQueries';
import { LocationListWrapper, LocationListSpacer, LocationListItem } from './dropOffLocationList.styles';
import DropOffLocation, { LocationProps } from '../DropOffLocation';
import TransitionButtons from '../TransitionButtons';
import { CarrierLocation } from '../types';

interface props extends LocationProps {
  locations: CarrierLocation[];
  includeTransitionButtons: boolean;
  nextButtonText?: string;
  onLocationClick?: (locationIndex: number) => void;
}

const DropOffLocationList = (props: props) => {
  const { isTabletWidth } = useMediaQueries();
  const { setOpenLocationIndex } = useGlobalStore();

  return (
    <>
      <LocationListWrapper>
        {props.locations.map((location, index) => {
          return (
            <LocationListItem
              key={`${location.displayName}-${location.locationIndex}`}
              onClick={() => {
                if (isTabletWidth) {
                  setOpenLocationIndex(location.locationIndex);
                }
              }}
            >
              <DropOffLocation location={location} {...props} />
              {props.locations.length !== index + 1 && <ListDivider />}
            </LocationListItem>
          );
        })}
        <LocationListSpacer />
      </LocationListWrapper>
      {props.includeTransitionButtons && (
        <ListGradient>
          <TransitionButtons text={props.nextButtonText} />
        </ListGradient>
      )}
    </>
  );
};

export default DropOffLocationList;
