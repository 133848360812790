import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';

export interface TypeSchedulePickupPanelFields {
  name: EntryFieldTypes.Symbol;
  title?: EntryFieldTypes.Symbol;
  selectDayTitle?: EntryFieldTypes.Symbol;
  selectDaySubtext?: EntryFieldTypes.Symbol;
  selectTimeTitle?: EntryFieldTypes.Symbol;
  selectTimeSubtext?: EntryFieldTypes.Symbol;
  selectContactTitle?: EntryFieldTypes.Symbol;
  selectContactSubtext?: EntryFieldTypes.Symbol;
  submitButtonText: EntryFieldTypes.Symbol;
}

export type TypeSchedulePickupPanelSkeleton = EntrySkeletonType<TypeSchedulePickupPanelFields, 'schedulePickupPanel'>;
export type TypeSchedulePickupPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypeSchedulePickupPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypeSchedulePickupPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeSchedulePickupPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'schedulePickupPanel';
}

export type TypeSchedulePickupPanelWithoutLinkResolutionResponse = TypeSchedulePickupPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypeSchedulePickupPanelWithoutUnresolvableLinksResponse =
  TypeSchedulePickupPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypeSchedulePickupPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> =
  TypeSchedulePickupPanel<'WITH_ALL_LOCALES', Locales>;
export type TypeSchedulePickupPanelWithAllLocalesAndWithoutLinkResolutionResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeSchedulePickupPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypeSchedulePickupPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypeSchedulePickupPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
