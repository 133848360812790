import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';

export const SchedulePickupPanelContainer = styled(PanelContainerStyles)`
  justify-content: flex-start;
  padding: 16px;
  gap: 24px;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
