import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import { HeaderContainer, ListDivider, RadioButton } from 'components/GenericStyles/generic.styles';
import BackButton from 'components/SharedComponents/BackButton';
import HelpButton from 'components/SharedComponents/HelpButton';
import LabelledTitle from 'components/SharedComponents/LabelledTitle';
import SectionDescription from 'components/SharedComponents/SectionDescription';
import { TypeSchedulePickupPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { useGlobalStore } from 'contexts/mainGlobalContext';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import { RadioButtonContainer, SchedulePickupPanelContainer } from './schedulePickupPanel.styles';

interface props {
  panel: TypeSchedulePickupPanelWithoutUnresolvableLinksResponse;
  openHelpModal: () => void;
}

const SchedulePickupPanel = (props: props) => {
  const { pickupAvailability } = useGlobalStore();
  const swiper = useSwiper();
  const [selectedDay, setSelectedDay] = useState<string>('Today, Oct 1');

  useEffect(() => {
    if (!pickupAvailability) {
      // We are only storing the pickupAvailability in the GlobalStore, so if the user refreshes or returns to the app,
      // it will be cleared. This is by design, as an effort to avoid using an old value, since this is time-sensitive
      // data. So if it's gone, we're just gonna return the user to the previous slide, so they can refresh the data.
      swiper.slidePrev();
    }
  }, [pickupAvailability]);

  return (
    <SchedulePickupPanelContainer>
      <HeaderContainer>
        <BackButton />
        <HelpButton {...props} />
      </HeaderContainer>
      {props.panel.fields.title && <LabelledTitle labelText="Schedule pickup" titleText={props.panel.fields.title} />}
      <ListDivider />
      {props.panel.fields.selectDayTitle && (
        <SectionDescription
          titleText={props.panel.fields.selectDayTitle}
          subtext={props.panel.fields.selectDaySubtext}
        />
      )}
      <RadioButtonContainer>
        <RadioButton checked={selectedDay === 'Today, Oct 1'} onChange={() => setSelectedDay('Today, Oct 1')} />
        <ThemeFonts.LabelLarge color={ThemeColors.TassoBlue}>Today, Oct 1</ThemeFonts.LabelLarge>
      </RadioButtonContainer>
      <RadioButtonContainer>
        <RadioButton checked={selectedDay === 'Tomorrow, Oct 2'} onChange={() => setSelectedDay('Tomorrow, Oct 2')} />
        <ThemeFonts.LabelLarge color={ThemeColors.TassoBlue}>Tomorrow, Oct 2</ThemeFonts.LabelLarge>
      </RadioButtonContainer>
      <ListDivider />
      {props.panel.fields.selectTimeTitle && (
        <SectionDescription
          titleText={props.panel.fields.selectTimeTitle}
          subtext={props.panel.fields.selectTimeSubtext}
        />
      )}
      <ListDivider />
      {props.panel.fields.selectContactTitle && (
        <SectionDescription
          titleText={props.panel.fields.selectContactTitle}
          subtext={props.panel.fields.selectContactSubtext}
        />
      )}
    </SchedulePickupPanelContainer>
  );
};

export default SchedulePickupPanel;
