import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful';

export interface TypePickupAddressPanelFields {
  name: EntryFieldTypes.Symbol;
  titleText: EntryFieldTypes.Symbol;
  option1Title: EntryFieldTypes.Symbol;
  option1Subtext: EntryFieldTypes.Symbol;
  option2Title: EntryFieldTypes.Symbol;
  option2Subtext: EntryFieldTypes.Symbol;
  submitButtonText: EntryFieldTypes.Symbol;
  invalidAddressMessage: EntryFieldTypes.Text;
}

export type TypePickupAddressPanelSkeleton = EntrySkeletonType<TypePickupAddressPanelFields, 'pickupAddressPanel'>;
export type TypePickupAddressPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<
  TypePickupAddressPanelSkeleton,
  Modifiers,
  Locales
>;

export function isTypePickupAddressPanel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePickupAddressPanel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'pickupAddressPanel';
}

export type TypePickupAddressPanelWithoutLinkResolutionResponse = TypePickupAddressPanel<'WITHOUT_LINK_RESOLUTION'>;
export type TypePickupAddressPanelWithoutUnresolvableLinksResponse =
  TypePickupAddressPanel<'WITHOUT_UNRESOLVABLE_LINKS'>;
export type TypePickupAddressPanelWithAllLocalesResponse<Locales extends LocaleCode = LocaleCode> =
  TypePickupAddressPanel<'WITH_ALL_LOCALES', Locales>;
export type TypePickupAddressPanelWithAllLocalesAndWithoutLinkResolutionResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypePickupAddressPanel<'WITHOUT_LINK_RESOLUTION' | 'WITH_ALL_LOCALES', Locales>;
export type TypePickupAddressPanelWithAllLocalesAndWithoutUnresolvableLinksResponse<
  Locales extends LocaleCode = LocaleCode,
> = TypePickupAddressPanel<'WITHOUT_UNRESOLVABLE_LINKS' | 'WITH_ALL_LOCALES', Locales>;
