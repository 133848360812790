import styled from 'styled-components';
import { TabletWidth } from 'styles/constants';

export const HelpIconSectionWrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  align-items: flex-start;
  ${(p) => p.$fullWidth && 'width: 100%;'}

  img {
    height: 24px;
    width: 24px;
  }

  svg {
    height: 24px;
    width: 24px;
  }

  @media (min-width: ${TabletWidth}) {
    margin-left: 0;
  }
`;
