import React from 'react';

import { TextFieldProps, TextFieldVariants } from '@mui/material';
import CircleCheckIcon from 'assets/CircleCheckIcon';
import CircleExclamationIcon from 'assets/CircleExclamationIcon';
import { InputField as BaseInputField } from './inputField.styles';

type BaseInputFieldProps = { variant?: TextFieldVariants } & Omit<TextFieldProps, 'variant'>;
interface InputFieldProps extends BaseInputFieldProps {
  status?: 'valid' | 'invalid';
}

const InputField = (props: InputFieldProps) => {
  let adornment: React.ReactNode | undefined = undefined;
  if (props.status === 'valid') {
    adornment = <CircleCheckIcon />;
  } else if (props.status === 'invalid') {
    adornment = <CircleExclamationIcon />;
  }
  return <BaseInputField InputProps={{ endAdornment: adornment, ...props.InputProps }} {...props} />;
};

export default InputField;
