import React from 'react';

import { DisplayProps, ThemeColors, ThemeFonts } from 'styles/constants';
import { LabelledTitleContainer } from './labelledTitle.styles';

interface LabelledTitleProps {
  labelText?: string;
  labelTextProps?: DisplayProps;
  titleText: string;
  titleTextProps?: DisplayProps;
}

const LabelledTitle = (props: LabelledTitleProps) => {
  return (
    <LabelledTitleContainer>
      {props.labelText && (
        <ThemeFonts.LabelLarge color={ThemeColors.TassoBrightBlue} {...props.labelTextProps}>
          {props.labelText}
        </ThemeFonts.LabelLarge>
      )}
      <ThemeFonts.DisplayMedium color={ThemeColors.TassoBlue} {...props.titleTextProps}>
        {props.titleText}
      </ThemeFonts.DisplayMedium>
    </LabelledTitleContainer>
  );
};

export default LabelledTitle;
