import React, { useEffect, useState } from 'react';
import InputField from 'components/SharedComponents/InputField';
import { AddressFormContainer, AddressFormRowContainer, ScrollSpacer } from './addressForm.styles';
import { Address } from '../types';

interface AddressFormProps {
  enableValidation: boolean;
  setAddress: (value: Address | null) => void;
  setAddressIsValid: (value: boolean) => void;
}

const AddressForm = (props: AddressFormProps) => {
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [district1, setDistrict1] = useState<string>('');
  const [country, setCountry] = useState<string>('US');

  const [address1IsValid, setAddress1IsValid] = useState<boolean>(true);
  const [cityIsValid, setCityIsValid] = useState<boolean>(true);
  const [postalCodeIsValid, setPostalCodeIsValid] = useState<boolean>(true);
  const [district1IsValid, setDistrict1IsValid] = useState<boolean>(true);
  const [countryIsValid, setCountryIsValid] = useState<boolean>(true);

  useEffect(() => {
    props.setAddress({
      address1,
      address2,
      city,
      postalCode,
      district1,
      country,
    });
  }, [address1, address2, city, postalCode, district1, country]);

  useEffect(() => {
    props.setAddressIsValid(address1IsValid && cityIsValid && postalCodeIsValid && district1IsValid && countryIsValid);
  }, [address1IsValid, cityIsValid, postalCodeIsValid, district1IsValid, countryIsValid]);

  useEffect(() => setAddress1IsValid(isValid(address1)), [address1]);
  useEffect(() => setCityIsValid(isValid(city)), [city]);
  useEffect(() => setPostalCodeIsValid(isValid(postalCode)), [postalCode]);
  useEffect(() => setDistrict1IsValid(isValid(district1)), [district1]);
  useEffect(() => setCountryIsValid(isValid(country)), [country]);

  const isValid = (value: string): boolean => {
    return value.length > 0;
  };

  return (
    <AddressFormContainer>
      <InputField
        label="Address 1"
        value={address1}
        onChange={(e) => setAddress1(e.target.value)}
        error={props.enableValidation && !address1IsValid}
        status={props.enableValidation && !address1IsValid ? 'invalid' : undefined}
      />
      <InputField label="Address 2" value={address2} onChange={(e) => setAddress2(e.target.value)} />
      <InputField
        label="City"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        error={props.enableValidation && !cityIsValid}
        status={props.enableValidation && !cityIsValid ? 'invalid' : undefined}
      />
      <AddressFormRowContainer>
        <InputField
          label="State"
          value={district1}
          inputProps={{ maxLength: 2, style: { textTransform: 'uppercase' } }}
          onChange={(e) => setDistrict1(e.target.value)}
          error={props.enableValidation && !district1IsValid}
          status={props.enableValidation && !district1IsValid ? 'invalid' : undefined}
        />
        <InputField
          label="Zip"
          value={postalCode}
          type="number"
          onChange={(e) => setPostalCode(e.target.value)}
          error={props.enableValidation && !postalCodeIsValid}
          status={props.enableValidation && !postalCodeIsValid ? 'invalid' : undefined}
        />
        <InputField
          label="Country"
          value={country}
          inputProps={{ maxLength: 2, style: { textTransform: 'uppercase' } }}
          onChange={(e) => setCountry(e.target.value)}
          error={props.enableValidation && !countryIsValid}
          status={props.enableValidation && !countryIsValid ? 'invalid' : undefined}
        />
      </AddressFormRowContainer>
      <ScrollSpacer />
    </AddressFormContainer>
  );
};

export default AddressForm;
