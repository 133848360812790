import { createTheme } from '@mui/material';
import { createGlobalStyle } from 'styled-components';
import { ThemeColors } from './constants';

// Fonts
import FigtreeBold from '../fonts/Figtree-Bold.ttf';
import FigtreeLight from '../fonts/Figtree-Light.ttf';
import FigtreeMedium from '../fonts/Figtree-Medium.ttf';
import Figtree from '../fonts/Figtree-Regular.ttf';

export const GlobalStyles = createGlobalStyle`
    :root {
        --site-max-width: 1200px;
    }

    @font-face {
        font-family: 'Figtree, sans-serif';
        src: url('${FigtreeLight}');
        font-weight: 200;
    }

    @font-face {
        font-family: 'Figtree, sans-serif';
        src: url('${Figtree}');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Figtree, sans-serif';
        src: url('${FigtreeMedium}');
        font-weight: 500;
    }
    
    @font-face {
        font-family: 'Figtree, sans-serif';
        src: url('${FigtreeMedium}');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Figtree, sans-serif';
        src: url('${FigtreeBold}');
        font-weight: 700;
    }

    html {
        height: 100%;
    }

    body {
        font-family: 'figtree, sans-serif';
        font-weight: 400;
        font-style: normal;
        line-height: 1.2;
        color: ${ThemeColors.TassoDarkBlue};
        margin: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
    }

    .swiper {
        overflow-y: scroll;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }

    .swiper-slide {
        font-size: 18px;
        background: ${ThemeColors.TassoWhite};
        display: flex;
    }

    .grecaptcha-badge { 
        visibility: hidden !important;
    }
`;

export const MuiTheme = createTheme({
  typography: {
    fontFamily: 'Figtree, sans-serif',
  },
});
