import styled from 'styled-components';
import { ThemeColors } from 'styles/constants';

export const BackButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: ${ThemeColors.TassoWhite};
  cursor: pointer;
  padding: 0;

  img: {
    width: 20px;
    height: 20px;
  }
`;
