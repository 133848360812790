import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth } from 'styles/constants';

export const MapPanelContiner = styled(PanelContainerStyles)`
  padding: 16px 0 0;
  gap: 0;
  justify-content: flex-start;

  @media (min-width: ${TabletWidth}) {
    padding: 24px 0 0 24px;
    overflow-y: hidden;
  }
`;

// Header
export const MapHeaderContainer = styled.div`
  gap: 8px;
`;

// Back Button
export const MapLocationBackButtonWrapper = styled.div`
  margin: 16px 0px 0px 17px;
`;

export const AddressSearchWrapper = styled.div`
  margin: 16px 16px 4px 16px;

  @media (min-width: ${TabletWidth}) {
    margin-top: 0;
  }
`;

export const StepWrapper = styled.div`
  margin: 0 16px;

  @media (min-width: ${TabletWidth}) {
    margin: 0 24px 0 0;
  }
`;

// No locations
export const MapLocationsNoneFoundWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 51px;
`;

export const MapLocationsNoneFoundText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
`;

// Transition Buttons for no locations found
export const MapLocationsTransitionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  margin: 8px 12px;
`;

// Tablet design
interface DropOffLocationProps {
  $hasLocations: boolean;
}
export const DropOffLocationContainer = styled.div<DropOffLocationProps>`
  display: grid;
  grid-template-columns: ${(p) => (p.$hasLocations ? '50% 50%' : '100%')};
  margin-top: 12px;

  @media (min-width: ${DesktopWidth}) {
    grid-template-columns: ${(p) => (p.$hasLocations ? '40% 60%' : '100%')};
  }
`;

export const LocationListContainer = styled.div`
  @media (min-width: ${DesktopWidth}) {
    max-width: 550px;
    width: 100%;
    justify-self: flex-end;
  }
`;
