import React, { SVGProps } from 'react';

export const CircleExclamationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_7048_6768)">
      <path
        d="M15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15C3 21.6274 8.37258 27 15 27Z"
        stroke="#EA5153"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 15.5V9" stroke="#EA5153" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15 21.3999C15.5891 21.3999 16.0667 20.9224 16.0667 20.3333C16.0667 19.7442 15.5891 19.2666 15 19.2666C14.4109 19.2666 13.9333 19.7442 13.9333 20.3333C13.9333 20.9224 14.4109 21.3999 15 21.3999Z"
        fill="#EA5153"
      />
    </g>
    <defs>
      <clipPath id="clip0_7048_6768">
        <rect width="32" height="32" fill="white" transform="translate(-1 -1)" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleExclamationIcon;
