import React, { createContext, useContext, useState, ReactNode } from 'react';
import { DateTime } from 'luxon';
import { ScheduledPickupAvailability } from 'components/PickupAddressPanel/types';

// Define the shape of the context state
interface GlobalStoreState {
  collectionDateTime?: DateTime;
  dropOffLocationSearchOrigin?: DropOffLocationSearchOrigin;
  activeBookmarkIndex: number;
  openLocationIndex: number | null;
  pickupAvailability: ScheduledPickupAvailability | null;
}
export interface DropOffLocationSearchOrigin {
  latitude: number;
  longitude: number;
  formattedAddress?: string;
  country?: string;
}

// Define the shape of the context functions
interface GlobalStoreContextProps extends GlobalStoreState {
  setCollectionDateTime: (dateTime: DateTime) => void;
  setDropOffLocationSearchOrigin: (location?: DropOffLocationSearchOrigin) => void;
  setActiveBookmarkIndex: (index: number) => void;
  setOpenLocationIndex: (value: number | null) => void;
  setPickupAvailability: (value: ScheduledPickupAvailability | null) => void;
}

// Create the context with a default value
const GlobalStoreContext = createContext<GlobalStoreContextProps | undefined>(undefined);

// Create a provider component
const GlobalStoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [collectionDateTime, setCollectionDateTime] = useState<DateTime | undefined>(undefined);
  const [dropOffLocationSearchOrigin, setDropOffLocationSearchOrigin] = useState<
    DropOffLocationSearchOrigin | undefined
  >(undefined);
  const [activeBookmarkIndex, setActiveBookmarkIndex] = useState<number>(-1);
  const [openLocationIndex, setOpenLocationIndex] = useState<number | null>(null);
  const [pickupAvailability, setPickupAvailability] = useState<ScheduledPickupAvailability | null>(null);

  return (
    <GlobalStoreContext.Provider
      value={{
        collectionDateTime,
        dropOffLocationSearchOrigin,
        activeBookmarkIndex,
        openLocationIndex,
        pickupAvailability,
        setCollectionDateTime,
        setDropOffLocationSearchOrigin,
        setActiveBookmarkIndex,
        setOpenLocationIndex,
        setPickupAvailability,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  );
};

// Custom hook to use the GlobalStoreContext
const useGlobalStore = () => {
  const context = useContext(GlobalStoreContext);
  if (!context) {
    throw new Error('useGlobalStore must be used within a GlobalStoreProvider');
  }
  return context;
};

export { GlobalStoreProvider, useGlobalStore };
