import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import CircleArrowIcon from 'assets/CircleArrowIcon';
import SadFaceIcon from 'assets/SadFaceIcon';
import { ErrorWrapper, HeaderContainer, ListDivider, RadioButton } from 'components/GenericStyles/generic.styles';
import ErrorPanel from 'components/SadPanels/ErrorPanel';
import BackButton from 'components/SharedComponents/BackButton';
import HelpButton from 'components/SharedComponents/HelpButton';
import LabelledTitle from 'components/SharedComponents/LabelledTitle';
import SectionDescription from 'components/SharedComponents/SectionDescription';
import { TypePickupAddressPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { useGlobalStore } from 'contexts/mainGlobalContext';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import { useMediaQueries } from 'utils/helpers/mediaQueries';
import AddressForm from './AddressForm';
import {
  AddressOnFileContainer,
  AddressOptionContainer,
  AddressOptionsContainer,
  PickupAddressContentContainer,
  PickupAddressPanelContainer,
  RadioButtonWrapper,
  SubmitButton,
  SubmitButtonContainer,
  TitleContainer,
} from './pickupAddressPanel.styles';
import { getPatientAddress, getPickupAvailability } from './requests';
import { Address } from './types';

interface props {
  panel: TypePickupAddressPanelWithoutUnresolvableLinksResponse;
  order: number;
  openHelpModal: () => void;
}

const PickupAddressPanel = (props: props) => {
  const swiper = useSwiper();
  const { setPickupAvailability } = useGlobalStore();
  const { isTabletWidth } = useMediaQueries();
  const [isActivePage, setIsActivePage] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<'onFile' | 'manualInput'>('onFile');
  const [displayUnexpectedErrorPanel, setDisplayUnexpectedErrorPanel] = useState<boolean>(false);
  const [addressOnFileErrorMessage, setAddressOnFileErrorMessage] = useState<string>('');
  const [manualAddressErrorMessage, setManualAddressErrorMessage] = useState<string>('');

  const [addressOnFile, setAddressOnFile] = useState<Address | null>(null);
  const [addressManualInput, setAddressManualInput] = useState<Address | null>(null);
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [manualInputAddressIsValid, setManualInputAddressIsValid] = useState<boolean>(false);

  const handleEvent = () => {
    setIsActivePage(swiper.activeIndex === props.order);
  };

  useEffect(() => {
    // In case someone refreshes this page directly, make sure map panel gets loaded
    handleEvent();

    swiper.on('slideChangeTransitionStart', handleEvent); // Fires when panel changes via swiper control.
  }, [swiper]);

  useEffect(() => {
    window.addEventListener('popstate', handleEvent); // Fires when back/forward navigation is used.
    return () => window.removeEventListener('popstate', handleEvent);
  });

  useEffect(() => {
    if (!isActivePage) {
      return;
    }
    setDisplayUnexpectedErrorPanel(false);

    const activationCode = localStorage.getItem('activationCode');
    if (!activationCode) {
      console.error('ERROR: Cannot retrieve address on file without an activationCode.');
      setDisplayUnexpectedErrorPanel(true);
      return;
    }

    getPatientAddress(activationCode).then((response) => {
      if (response.status === 'error') {
        setDisplayUnexpectedErrorPanel(true);
      } else if (response.status === 'fail') {
        setAddressOnFileErrorMessage(props.panel.fields.invalidAddressMessage);
      } else {
        setAddressOnFile(response.body!);
      }
    });
  }, [isActivePage]);

  useEffect(() => {
    if (validateForm) {
      setManualAddressErrorMessage(manualInputAddressIsValid ? '' : 'Please fill in missing fields');
    }
  }, [manualInputAddressIsValid, validateForm]);

  const onSubmit = async () => {
    if (selectedOption === 'manualInput') {
      setValidateForm(true);
      if (!manualInputAddressIsValid) {
        return;
      }
    }

    const activationCode = localStorage.getItem('activationCode');
    if (!activationCode) {
      console.error('ERROR: Cannot retrieve pickup availability without an activationCode.');
      setDisplayUnexpectedErrorPanel(true);
      return;
    }

    const address: Address = selectedOption === 'onFile' ? addressOnFile! : addressManualInput!;

    setIsButtonLoading(true);
    const availabilityResponse = await getPickupAvailability(activationCode, address);
    setIsButtonLoading(false);
    if (availabilityResponse.status === 'error') {
      setDisplayUnexpectedErrorPanel(true);
    } else if (availabilityResponse.status === 'fail') {
      if (selectedOption === 'onFile') {
        setAddressOnFileErrorMessage(props.panel.fields.invalidAddressMessage);
      } else {
        setManualAddressErrorMessage(props.panel.fields.invalidAddressMessage);
      }
    } else {
      setPickupAvailability(availabilityResponse.body!);
      swiper.slideNext();
    }
  };

  const canSubmit = (): boolean => {
    const invalidOnFile = selectedOption === 'onFile' && !addressOnFile;
    const invalidManualInput = selectedOption === 'manualInput' && validateForm && !manualInputAddressIsValid;
    return isButtonLoading || invalidOnFile || invalidManualInput;
  };

  if (displayUnexpectedErrorPanel) {
    return <ErrorPanel errorCode="500" openHelpModal={props.openHelpModal} />;
  }

  return (
    <PickupAddressPanelContainer>
      <PickupAddressContentContainer>
        <HeaderContainer>
          <BackButton />
          <HelpButton {...props} />
        </HeaderContainer>
        <TitleContainer>
          <LabelledTitle labelText="Schedule pickup" titleText={props.panel.fields.titleText} />
        </TitleContainer>
        <AddressOptionsContainer>
          <AddressOptionContainer>
            <ListDivider />
            <RadioButtonWrapper>
              <RadioButton checked={selectedOption === 'onFile'} onChange={() => setSelectedOption('onFile')} />
              <SectionDescription
                titleText={props.panel.fields.option1Title}
                subtext={props.panel.fields.option1Subtext}
              />
            </RadioButtonWrapper>
            {addressOnFileErrorMessage && (
              <ErrorWrapper>
                <SadFaceIcon />
                {addressOnFileErrorMessage}
              </ErrorWrapper>
            )}
            {(isTabletWidth || selectedOption === 'onFile') && (
              <AddressOnFileContainer>
                <ThemeFonts.LabelMedium>Zip code on file:</ThemeFonts.LabelMedium>
                <ThemeFonts.LabelMedium>{addressOnFile?.postalCode ?? 'Loading...'}</ThemeFonts.LabelMedium>
              </AddressOnFileContainer>
            )}
          </AddressOptionContainer>
          <AddressOptionContainer>
            <ListDivider />
            <RadioButtonWrapper>
              <RadioButton
                checked={selectedOption === 'manualInput'}
                onChange={() => setSelectedOption('manualInput')}
              />
              <SectionDescription
                titleText={props.panel.fields.option2Title}
                subtext={props.panel.fields.option2Subtext}
              />
            </RadioButtonWrapper>
            {(isTabletWidth || selectedOption === 'manualInput') && (
              <>
                {manualAddressErrorMessage && (
                  <ErrorWrapper>
                    <SadFaceIcon />
                    {manualAddressErrorMessage}
                  </ErrorWrapper>
                )}
                <AddressForm
                  enableValidation={validateForm}
                  setAddress={setAddressManualInput}
                  setAddressIsValid={setManualInputAddressIsValid}
                />
              </>
            )}
          </AddressOptionContainer>
        </AddressOptionsContainer>
      </PickupAddressContentContainer>
      <SubmitButtonContainer>
        <SubmitButton disabled={canSubmit()} onClick={onSubmit}>
          <ThemeFonts.LabelLarge color={ThemeColors.TassoWhite}>
            {props.panel.fields.submitButtonText}
          </ThemeFonts.LabelLarge>
          <CircleArrowIcon />
        </SubmitButton>
      </SubmitButtonContainer>
    </PickupAddressPanelContainer>
  );
};

export default PickupAddressPanel;
