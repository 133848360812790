import { TextField } from '@mui/material';
import styled from 'styled-components';
import { TabletWidth, ThemeColors } from 'styles/constants';

export const InputField = styled(TextField)`
  & label.Mui-focused {
    color: ${ThemeColors.HeavyGray};
  }

  & .MuiOutlinedInput-root {
    border-radius: 7px;
    height: 48px;

    & input {
      color: ${ThemeColors.TassoBlue};
    }

    &:hover fieldset {
      border-color: ${ThemeColors.DarkGray};
    }
    &.Mui-focused fieldset {
      border-color: ${ThemeColors.TassoViolet};
    }

    @media (min-width: ${TabletWidth}) {
      height: 56px;
    }
  }
`;
