import React from 'react';

import { ThemeColors, ThemeFonts } from 'styles/constants';
import { SectionDescriptionContainer } from './sectionDescription.styles';

interface SectionDescriptionProps {
  titleText: string;
  subtext?: string;
}

const SectionDescription = (props: SectionDescriptionProps) => {
  return (
    <SectionDescriptionContainer>
      <ThemeFonts.LabelLarge color={ThemeColors.TassoDarkBlue}>{props.titleText}</ThemeFonts.LabelLarge>
      <ThemeFonts.TitleMedium color={ThemeColors.TassoDarkBlue}>{props.subtext}</ThemeFonts.TitleMedium>
    </SectionDescriptionContainer>
  );
};

export default SectionDescription;
