import React from 'react';

import { HeaderContainer } from 'components/GenericStyles/generic.styles';
import BackButton from 'components/SharedComponents/BackButton';
import HelpButton from 'components/SharedComponents/HelpButton';
import LabelledTitle from 'components/SharedComponents/LabelledTitle';
import NextButton from 'components/SharedComponents/NextButton';
import { TypeInfoTextPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import {
  BackgroundIconStyles,
  ContentWrapper,
  InfoPanelContainerStyles,
  FooterWrapper,
  BodyWrapper,
} from './infoSectionPanel.styles';
const InfoSectionPanel = (props: {
  panel: TypeInfoTextPanelWithoutUnresolvableLinksResponse;
  openHelpModal: () => void;
}) => {
  const {
    subText,
    backgroundIcon,
    tertiaryText,
    nextButtonText,
    titleText,
    nextButtonPrefixText,
    nextButtonLink,
    includeBackButton,
    sectionLabel,
  } = props.panel.fields;

  return (
    <InfoPanelContainerStyles>
      <HeaderContainer>
        {includeBackButton && <BackButton />}
        <HelpButton {...props} fullWidth={!includeBackButton} />
      </HeaderContainer>
      <BodyWrapper>
        <ContentWrapper>
          <div>
            {subText && <ThemeFonts.TitleLarge color={ThemeColors.HeavyGray}>{subText}</ThemeFonts.TitleLarge>}
            {titleText && (
              <LabelledTitle titleText={titleText} titleTextProps={{ margin: '12px 0' }} labelText={sectionLabel} />
            )}
          </div>
          {tertiaryText && <ThemeFonts.BodyLarge color={ThemeColors.HeavyGray}>{tertiaryText}</ThemeFonts.BodyLarge>}
        </ContentWrapper>
        {backgroundIcon?.fields.file?.url && backgroundIcon?.fields.title && (
          <BackgroundIconStyles>
            <img src={backgroundIcon.fields.file.url} alt={backgroundIcon.fields.title} />
          </BackgroundIconStyles>
        )}
      </BodyWrapper>

      <FooterWrapper>
        {nextButtonPrefixText && <ThemeFonts.BodyLarge>{nextButtonPrefixText}</ThemeFonts.BodyLarge>}
        <NextButton nextButtonText={nextButtonText} nextButtonLink={nextButtonLink} />
      </FooterWrapper>
    </InfoPanelContainerStyles>
  );
};

export default InfoSectionPanel;
