import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from 'styled-components';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

// Progress Bar
export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LabelledProgressBarWrapper = styled.div`
  flex: 1;
  margin: 8px 22px 0 0;

  @media (min-width: ${TabletWidth}) {
    max-width: 510px;
    margin: 8px auto 0;
  }

  @media (min-width: ${DesktopWidth}) {
    padding-left: 24px;
    max-width: 1000px;
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  gap: 4px;

  span {
    height: 4px;
    width: 100%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const ProgressPhaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex: 1 1 0px;
`;

export const ProgressBarTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
`;

interface BorderLinearProgressProps {
  $isLightMode?: boolean;
}

export const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>((props) => ({
  height: 3,
  borderRadius: 40,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: props.$isLightMode ? ThemeColors.DarkGray : ThemeColors.CloudyGray,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 40,
    backgroundColor: props.$isLightMode ? ThemeColors.TassoWhite : ThemeColors.TassoBlue,
  },
}));
