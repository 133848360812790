import styled from 'styled-components';
import { PanelContainerStyles } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth, ThemeColors } from 'styles/constants';

// Container
export const PhasePanelContainerStyles = styled(PanelContainerStyles)`
  padding-bottom: 16px;
  gap: 0;

  @media (min-width: ${TabletWidth}) {
    padding-bottom: 24px;
  }
`;

// Progress Bar
export const ProgressPhaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0px;
  gap: 4px;
  margin-top: 8px;
`;

export const BarWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  gap: 4px;
  max-width: 510px;
  flex: 1;
  margin: auto;
  padding-right: 22px;

  span {
    height: 4px;
    width: 100%;
  }

  @media (min-width: ${DesktopWidth}) {
    max-width: 1104px;
  }
`;

// Main Text Components
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ThemeColors.TassoBlue};
  padding: 16px;
  border-radius: 0px 0px 24px 24px;

  @media (min-width: ${TabletWidth}) {
    padding: 24px;
  }
`;

export const PhaseTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const PhaseNumber = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: ${ThemeColors.TassoWhite};
  text-align: center;
  color: ${ThemeColors.TassoBlue};
  font-size: 30px;
`;

export const PhaseTitleSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
  gap: 12px;

  @media (min-width: ${TabletWidth}) {
    height: 260px;
  }

  @media (min-width: ${DesktopWidth}) {
    height: 160px;
  }
`;

// Summary Section
export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  height: 100%;
  gap: 16px;
  margin-top: 24px;

  @media (min-width: ${TabletWidth}) {
    max-width: 540px;
    margin: 80px auto 0;
  }

  @media (min-width: ${DesktopWidth}) {
    margin-top: 32px;
  }
`;
export const SummaryTextWrapper = styled.div`
  padding-bottom: 16px;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StepImageContainer = styled.div`
  height: 56px;
  width: 84px;
  text-align: center;
`;

export const StepImage = styled.img`
  height: 56px;
  width: 84px;
`;

export const SwipeWrapper = styled.div`
  @media (min-width: ${TabletWidth}) {
    margin-bottom: 32px;
  }
`;
