import styled from 'styled-components';
import { ListGradient, PanelContainerStyles, PrimaryButtonStyles } from 'components/GenericStyles/generic.styles';
import { DesktopWidth, TabletWidth } from 'styles/constants';

export const PickupAddressPanelContainer = styled(PanelContainerStyles)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const PickupAddressContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  gap: 40px;
`;

export const TitleContainer = styled.div`
  @media (min-width: ${TabletWidth}) {
    padding: 0 96px;
  }
  @media (min-width: ${DesktopWidth}) {
    display: flex;
    justify-content: center;
    padding: 0px 128px;
  }
`;

export const AddressOnFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 40px;
  border-radius: 16px;
  background-color: #091f5b1a;
  align-items: center;
`;

export const AddressOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${TabletWidth}) {
    gap: 40px;
    padding: 0 96px;
  }

  @media (min-width: ${DesktopWidth}) {
    flex-direction: row;
    justify-content: center;
    padding: 0 128px;
  }
`;

export const AddressOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const SubmitButtonContainer = styled(ListGradient)`
  justify-content: center;
`;

export const SubmitButton = styled(PrimaryButtonStyles)`
  @media (min-width: ${TabletWidth}) {
    display: flex;
    justify-content: center;
    margin: 0px 176px;
    flex: 1;
  }
`;
