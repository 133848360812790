import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import BackArrowIcon from 'assets/BackArrowIcon';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import { BackButtonContainer } from './backButton.styles';

const BackButton = () => {
  const swiper = useSwiper();

  return (
    <BackButtonContainer onClick={() => swiper.slidePrev()}>
      <BackArrowIcon color={ThemeColors.TassoBlue} />
      <ThemeFonts.LabelMedium color={ThemeColors.TassoDarkBlue}>Back</ThemeFonts.LabelMedium>
    </BackButtonContainer>
  );
};

export default BackButton;
