import styled from 'styled-components';
import { TabletWidth, ThemeColors } from 'styles/constants';

export const LocationListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px 0px 16px;
  overflow-y: scroll;

  @media (min-width: ${TabletWidth}) {
    max-height: 95vh;
    box-sizing: border-box;
    padding-bottom: 80px;
    margin: 0;
  }
`;

export const LocationListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LocationListSpacer = styled.div`
  padding-top: 248px;
  background-color: ${ThemeColors.TassoWhite};
`;
