import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import ErrorPanel from 'components/SadPanels/ErrorPanel';
import LoadingPanel from 'components/SadPanels/LoadingPanel';
import BackButton from 'components/SharedComponents/BackButton';
import { TypeMapPanelWithoutUnresolvableLinksResponse } from 'contentmodels';
import { useGlobalStore } from 'contexts/mainGlobalContext';
import { ThemeColors, ThemeFonts } from 'styles/constants';
import { useMediaQueries } from 'utils/helpers/mediaQueries';
import { PATIENT_API_URL, httpRequestHeaders } from 'utils/helpers/patientApi';
import { LocationProps } from './DropOffLocation';
import DropOffLocationList from './DropOffLocationList';
import DropOffLocationMap from './DropOffLocationMap';
import {
  MapLocationsNoneFoundWrapper,
  MapLocationsTransitionButtonsWrapper,
  StepWrapper,
  MapHeaderContainer,
  MapLocationsNoneFoundText,
  AddressSearchWrapper,
  DropOffLocationContainer,
  MapPanelContiner,
  LocationListContainer,
  MapLocationBackButtonWrapper,
} from './mapPanel.styles';
import NavBar from './NavBar';
import TransitionButtons from './TransitionButtons';
import { CarrierLocation, DropOffLocationsResponse } from './types';
import ListBulletsIcon from '../../assets/ListBulletsIcon';
import MapTrifoldIcon from '../../assets/MapTrifoldIcon';
import AddressAutocomplete from '../SharedComponents/AddressAutocomplete';
import StepsProgressBar from '../SharedComponents/StepsProgressBar';

interface props {
  panel: TypeMapPanelWithoutUnresolvableLinksResponse;
  phaseName: string;
  progressBarStepNum: number;
  progressBarStepTotal: number;
  phases: string[];
  order: number;
  openHelpModal: () => void;
}

const MapPanel = (props: props) => {
  const { dropOffLocationSearchOrigin, setDropOffLocationSearchOrigin, activeBookmarkIndex, setActiveBookmarkIndex } =
    useGlobalStore();
  const swiper = useSwiper();
  const [displayMode, setDisplayMode] = useState<'list' | 'map'>('list');
  const [dropOffLocations, setDropOffLocations] = useState<CarrierLocation[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayUnexpectedErrorPanel, setDisplayUnexpectedErrorPanel] = useState<boolean>(false);
  const [isActivePage, setIsActivePage] = useState<boolean>(false);

  const { setOpenLocationIndex } = useGlobalStore();
  const { isTabletWidth } = useMediaQueries();

  const handleEvent = () => {
    setIsActivePage(swiper.activeIndex === props.order);
  };

  useEffect(() => {
    // In case someone refreshes this page directly, make sure map panel gets loaded
    setIsActivePage(swiper.activeIndex === props.order);

    swiper.on('slideChangeTransitionStart', handleEvent); // Fires when panel changes via swiper control.
  }, [swiper]);

  useEffect(() => {
    window.addEventListener('popstate', handleEvent); // Fires when back/forward navigation is used.
    return () => window.removeEventListener('popstate', handleEvent);
  });

  useEffect(() => {
    if (!isActivePage) {
      return;
    }

    const dropOffLat = localStorage.getItem('dropOffLatitude');
    const dropOffLong = localStorage.getItem('dropOffLongitude');
    const dropOffAddress = localStorage.getItem('dropOffAddress');
    const dropOffCountry = localStorage.getItem('dropOffCountry');
    const lsDropOffSearchOrigin = {
      latitude: Number(dropOffLat),
      longitude: Number(dropOffLong),
      formattedAddress: dropOffAddress,
      country: dropOffCountry,
    };
    setDisplayUnexpectedErrorPanel(false);
    const activationCode = localStorage.getItem('activationCode');
    if (!activationCode) {
      setDisplayUnexpectedErrorPanel(true);
      return;
    }
    setIsLoading(true);
    let abandonFetch = false;
    const headers = httpRequestHeaders({});
    fetch(`${PATIENT_API_URL}/dropOffLocations`, {
      method: 'POST',
      body: JSON.stringify({
        maxResults: 15,
        latitude: lsDropOffSearchOrigin.latitude,
        longitude: lsDropOffSearchOrigin.longitude,
        country: lsDropOffSearchOrigin.country ?? 'US',
        activationCode,
      }),
      headers,
    })
      .then(async (response: Response) => {
        setIsLoading(false);
        if (!abandonFetch) {
          if (response.status === 200) {
            const responseBody: DropOffLocationsResponse = await response.json();
            const indexedLocations = responseBody.carrierLocations.map((loc, index) => ({
              ...loc,
              locationIndex: index,
            }));
            setDropOffLocations(indexedLocations);
          } else {
            setDisplayUnexpectedErrorPanel(true);
          }
        }
      })
      .catch((ex) => {
        console.error(ex);
        setDisplayUnexpectedErrorPanel(true);
      });

    return () => {
      // https://react.dev/learn/synchronizing-with-effects#fetching-data
      abandonFetch = true;
      setIsLoading(false);
    };
  }, [isActivePage, dropOffLocationSearchOrigin]);

  const onClickBookmark = (clickedLocationId: number) => {
    if (activeBookmarkIndex === clickedLocationId) {
      setActiveBookmarkIndex(-1);
      localStorage.setItem('activeBookmarkIndex', '-1');
    } else {
      setActiveBookmarkIndex(clickedLocationId);
      setOpenLocationIndex(clickedLocationId);
      localStorage.setItem('activeBookmarkIndex', clickedLocationId.toString());
    }
  };

  const onPlaceSelectEvent = (event: google.maps.places.PlaceResult | null) => {
    if (event?.geometry?.location) {
      const country = event.address_components?.find((component) => component.types.includes('country'))?.short_name;
      localStorage.setItem('dropOffLatitude', event.geometry.location.lat().toString());
      localStorage.setItem('dropOffLongitude', event.geometry.location.lng().toString());
      localStorage.setItem('dropOffAddress', event.formatted_address!);
      localStorage.setItem('dropOffCountry', country!);
      setDropOffLocationSearchOrigin({
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
        formattedAddress: event.formatted_address,
        country: country,
      });
    }
  };

  const getSearchOrigin = () => {
    if (dropOffLocationSearchOrigin) {
      return dropOffLocationSearchOrigin;
    }

    const lsDropOffLatitude = localStorage.getItem('dropOffLatitude');
    const lsDropOffLongitude = localStorage.getItem('dropOffLongitude');
    if (lsDropOffLatitude && lsDropOffLongitude) {
      return { latitude: parseFloat(lsDropOffLatitude), longitude: parseFloat(lsDropOffLongitude) };
    }
  };

  const locationProps: LocationProps = {
    onClickBookmark,
    activeBookmarkIndex,
    bookmarkingEnabled: props.panel.fields.assignableBookmarks,
    includeGoogleMapsLink: props.panel.fields.includeGoogleMapsLink,
  };
  const includeTransitionButtons = props.panel.fields.includeTransitionButtons;

  const commonHeaderContent = (
    <>
      {props.panel.fields.includeBackButton && (
        <MapLocationBackButtonWrapper>
          <BackButton />
        </MapLocationBackButtonWrapper>
      )}
      {props.panel.fields.includeProgressBar && (
        <StepWrapper>
          <StepsProgressBar {...props} openHelpModal={props.openHelpModal} />
        </StepWrapper>
      )}
    </>
  );

  const noLocationsFound = (
    <MapLocationsNoneFoundWrapper>
      <MapLocationsNoneFoundText>
        <ThemeFonts.HeadlineMedium color={ThemeColors.HeavyGray}>No locations found</ThemeFonts.HeadlineMedium>
        <ThemeFonts.TitleLarge color={ThemeColors.HeavyGray}>Please try another location.</ThemeFonts.TitleLarge>
      </MapLocationsNoneFoundText>
      {!isTabletWidth && includeTransitionButtons && (
        <MapLocationsTransitionButtonsWrapper>
          <TransitionButtons text={props.panel.fields.nextButtonText} />
        </MapLocationsTransitionButtonsWrapper>
      )}
    </MapLocationsNoneFoundWrapper>
  );

  const dualColumnView = (
    <MapPanelContiner>
      <MapHeaderContainer>{commonHeaderContent}</MapHeaderContainer>

      <DropOffLocationContainer $hasLocations={!!dropOffLocations && dropOffLocations.length > 0}>
        <LocationListContainer>
          <AddressSearchWrapper>
            <AddressAutocomplete onPlaceSelect={onPlaceSelectEvent} locationOverride={dropOffLocationSearchOrigin} />
          </AddressSearchWrapper>
          {dropOffLocations?.length === 0 && noLocationsFound}
          {dropOffLocations && dropOffLocations?.length > 0 && (
            <DropOffLocationList locations={dropOffLocations} includeTransitionButtons={false} {...locationProps} />
          )}
        </LocationListContainer>
        {dropOffLocations && dropOffLocations.length > 0 && (
          <DropOffLocationMap
            searchOrigin={getSearchOrigin()}
            locations={dropOffLocations}
            includeTransitionButtons={false}
            {...locationProps}
          />
        )}
      </DropOffLocationContainer>

      {includeTransitionButtons && <TransitionButtons overlay text={props.panel.fields.nextButtonText} />}
    </MapPanelContiner>
  );

  const singleColumnView = (
    <MapPanelContiner>
      <MapHeaderContainer>
        {commonHeaderContent}
        <AddressSearchWrapper>
          <AddressAutocomplete onPlaceSelect={onPlaceSelectEvent} locationOverride={dropOffLocationSearchOrigin} />
        </AddressSearchWrapper>
        <NavBar
          items={[
            {
              label: 'List',
              icon: <ListBulletsIcon width={23} height={23} />,
              onClick: () => setDisplayMode('list'),
            },
            {
              label: 'Map',
              icon: <MapTrifoldIcon width={23} height={23} />,
              onClick: () => setDisplayMode('map'),
            },
          ]}
        />
      </MapHeaderContainer>
      {dropOffLocations?.length === 0 && noLocationsFound}
      {dropOffLocations && dropOffLocations?.length > 0 && displayMode === 'list' && (
        <DropOffLocationList
          locations={dropOffLocations}
          includeTransitionButtons={includeTransitionButtons}
          nextButtonText={props.panel.fields.nextButtonText}
          {...locationProps}
        />
      )}
      {dropOffLocations && dropOffLocations?.length > 0 && displayMode === 'map' && (
        <DropOffLocationMap
          searchOrigin={getSearchOrigin()}
          locations={dropOffLocations}
          includeTransitionButtons={includeTransitionButtons}
          nextButtonText={props.panel.fields.nextButtonText}
          {...locationProps}
        />
      )}
    </MapPanelContiner>
  );

  if (displayUnexpectedErrorPanel) {
    // For when the API request fails or we are missing required data that should be defined already.
    return <ErrorPanel errorCode="500" openHelpModal={props.openHelpModal} />;
  }

  if (isLoading) {
    return <LoadingPanel openHelpModal={props.openHelpModal} />;
  }
  if (isTabletWidth) {
    return dualColumnView;
  }

  return singleColumnView;
};

export default MapPanel;
