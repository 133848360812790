import React, { SVGProps } from 'react';

export const CircleCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6869_41242)">
      <g clipPath="url(#clip1_6869_41242)">
        <path d="M10 16L13 19L20 12" stroke="#545557" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15C3 21.6274 8.37258 27 15 27Z"
          stroke="#545557"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6869_41242">
        <rect width="30" height="30" fill="white" />
      </clipPath>
      <clipPath id="clip1_6869_41242">
        <rect width="32" height="32" fill="white" transform="translate(-1 -1)" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleCheckIcon;
